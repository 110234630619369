import React, { useState, useEffect, useRef } from "react";
import { getBookingByCancelToken } from "../../actions/bookingAction";
import { cancelEvent } from "../../actions/admin/scheduled-event-actions";
import TimeLeftIcon from "../../assets/icons/time_left.svg";
import ClockIcon from "../../assets/icons/Clock.svg";
import CalendarIcon from "../../assets/icons/calendar.svg";
import Logo from "../../assets/images/logo-new.svg";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { notification } from "../../utils/utility";
import isEmpty from "../../validation/is-empty";
import moment from "moment";

const CancelBooking = (props) => {
  const { token, time } = useParams();

  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState(null);
  const cacncelReasonElement = useRef();

  useEffect(() => {
    const { getBookingByCancelToken } = props;
    setPageLoading(true);
    getBookingByCancelToken({
      data: {
        token,
      },
      onSuccess: function (response) {
        setData(response);
        setPageLoading(false);
      },
      onError: function (error) {
        notification.error("Invalid token, or token is expired.");
        setPageLoading(false);
      },
    });
  }, []);

  /**
   * cancel booking
   */
  const onCancelBooking = () => {
    const cancelReason = cacncelReasonElement.current.value;
    const { cancelEvent } = props;
    setPageLoading(true);
    cancelEvent({
      data: {
        booking_id: data.id,
        cancellation_reason: cancelReason,
        booking_status: !isEmpty(time) ? 'CC' : 'CS',
        is_invitee: !isEmpty(time) ? 'E' : 'D'
      },
      onSuccess: function (response) {
        notification.success("Booking is cancelled successfully.");
        setPage(2);
        setPageLoading(false);
      },
      onError: function (error) {
        notification.error(
          "An Error Occured during Cancellation, Please try later"
        );
        setPageLoading(false);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={pageLoading} />
      {page === 1 && (
        <div className="rd_vacationfilterpart justify-content-center cancel_eve">
          <div className="confrim_wrapper">
            {data && (
              <div className="txt text-center">
                <img src={Logo} className="logo" alt="Brand Logo" />
                <h4>{data.invitee_name}</h4>
                <h2>{data.event_name}</h2>
                <div className="info">
                  <p className="time_left">
                    <img src={TimeLeftIcon} alt="Time Icon" />{" "}
                    <span>{data.event_duration}</span>
                  </p>
                  <p>
                    <img src={CalendarIcon} alt="Time Icon" />{" "}
                    <span>{`${moment(data.start_time, "hh:mm").format(
                      "hh:mm a"
                    )} - ${moment(data.end_time, "hh:mm").format(
                      "hh:mm a"
                    )}, ${moment(data.when, "DD-MM-YYYY").format(
                      "dddd, MMMM DD, YYYY"
                    )}`}</span>
                  </p>
                  <p>
                    <img src={ClockIcon} alt="Time Icon" />{" "}
                    <span>{data.invitee_timezone}</span>
                  </p>
                </div>
              </div>
            )}
            <div className="field">
              <h3>Cancel Event?</h3>
              <div className="form-group">
                <label htmlFor="cancelingReason">Reason for canceling</label>
                <textarea
                  ref={cacncelReasonElement}
                  name="cancelingReason"
                  id=""
                  className="form-control"
                ></textarea>
                <button
                  className="btn rd_cancel mt-4"
                  onClick={onCancelBooking}
                >
                  Cancel Event
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {page === 2 && (
        <div className="rd_vacationfilterpart justify-content-center cancel_eve">
          <div className="confrim_wrapper text-center">
            {data && (
              <>
                <div className="rd_vacationfilterpart justify-content-center cancel_eve">
                  <div className="confrim_wrapper text-center">
                    <div className="txt p-0">
                      <h4>{data.invitee_name}</h4>
                      <h2 className="mb-2 pb-1">Cancellation Confirmed</h2>
                      <p>Your meeting with {data.host} has been cancelled</p>
                    </div>
                    <div className="info">
                      <h2 className="eve_name mt-0">
                        <span></span> {data.event_name}
                      </h2>
                      <p className="text-decoration-line-through">
                        <img src={CalendarIcon} alt="Time Icon" />{" "}
                        <span>{`${moment(data.start_time, "hh:mm").format(
                          "hh:mm a"
                        )} - ${moment(data.end_time, "hh:mm").format(
                          "hh:mm a"
                        )}, ${moment(data.when, "DD-MM-YYYY").format(
                          "dddd, MMMM DD, YYYY"
                        )}`}</span>
                      </p>
                      <p className="text-decoration-line-through">
                        <img src={ClockIcon} alt="Time Icon" />{" "}
                        <span>{data.invitee_timezone}</span>
                      </p>
                    </div>
                    <div className="foot">
                      <h2 className="mb-4">Simple. Automated. Scheduling.</h2>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          value="mateen@gmail.com"
                          aria-label="Username"
                          aria-describedby="quickSignup"
                        />
                        <button
                          class="btn rd_cancel"
                          type="button"
                          id="quickSignup"
                        >
                          Sign up free
                        </button>
                      </div>
                      <p className="text-left mt-2">No credit card required.</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default connect(null, {
  getBookingByCancelToken,
  cancelEvent,
})(CancelBooking);
