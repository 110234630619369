import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getBookingDetails,
  setBookingStatus,
} from "../../../actions/admin/scheduled-event-actions";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import { FRONTEND_BASE_URL } from "../../../utils/utility";
import {
  notification,
  onModalOpen,
  onModalClose,
} from "../../../utils/utility";
import LinkIcon from "../../../assets/icons/link_icon.svg";

const PastViewDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState(null);
  const [noShowModal, setNoShowModal] = useState(false);
  const toggleNoShowModal = () => {
    if (!noShowModal) {
      onModalOpen();
    } else {
      onModalClose();
    }
    setNoShowModal(!noShowModal);
  };

  useEffect(() => {
    const { getBookingDetails, bookingId } = props;
    setIsLoading(true);
    getBookingDetails({
      data: {
        id: bookingId,
      },
      onSuccess: function (response) {
        setData(response);
        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  }, []);

  /**
   * on copy event link
   * @param {*} eventURL
   * @param {*} copyFieldElementId
   */
  const onCopyEventLink = (eventURL) => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = FRONTEND_BASE_URL + "booking/" + eventURL;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    notification.success(
      `Event URL Copied ${FRONTEND_BASE_URL}booking/${eventURL}`
    );
    document.body.removeChild(tempInput);
  };

  /**
   * on mark as no show
   */
  const setStatus = (status) => {
    const { id } = data;
    const { setBookingStatus, updatePastEventStatus } = props;
    setIsLoading(true);
    setBookingStatus({
      data: {
        id: id,
        status,
      },
      onSuccess: function (response) {
        if (status === "MN") {
          toggleNoShowModal();
        }
        setData({
          ...data,
          booking_status: status,
        });
        updatePastEventStatus(id, status);
        notification.success("Status Successfully updated");

        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        isOpen={props.showViewDetailModal}
        toggle={props.handleClose}
        className="modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h3 className="rd_modalheadertext rd_modalheadertextnm">
                Booking Details
              </h3>
              <Row>
                <Col lg="12">
                  <div className="vd-title">Event Title :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.event_name : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">Host :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.host : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">Invitee :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.invitee : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">When :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.when : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">Event Duration :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.event_duration : null}
                  </div>
                </Col>

                <Col lg="12">
                  <div className="vd-title">Venue :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.venue : null}
                  </div>
                </Col>

                <Col lg="12">
                  <div className="vd-title">Venue Details :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.venue_details : null}
                  </div>
                </Col>
                {/* {data && data.event_url && (
                  <div class="col-lg-12 mt-4">
                    <div
                      class="copy_link_btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => onCopyEventLink(data.event_url)}
                    >
                      Copy event link
                    </div>
                  </div>
                )} */}
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getBookingDetails,
  setBookingStatus,
})(PastViewDetails);
