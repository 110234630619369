import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  upcomingEvents,
  requestInviteeToRescheduleAction,
} from "../../../../actions/admin/scheduled-event-actions";
import isEmpty from "../../../../validation/is-empty";
import Loader from "../../../../components/Loader/Loader";
import { Pagination } from "antd";
import filterIcon from "../../../../assets/icons/filter_icon.svg";
import { gotProperty } from "../../../../utils/utility";
import AdminIcon from "../../../../assets/icons/admin-icon.svg";
import InviteeIcon from "../../../../assets/icons/invitee-icon.svg";
import { notification, getVenueIcon } from "../../../../utils/utility";
import { Row, Col, Input } from "reactstrap";
import moment from "moment";
import UpcomingViewDetailsModal from "./upcoming-view-details-modal";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import UpcomingCancelModal from "./upcoming-cancel-modal";
import ReassignModal from "./reassign-modal";
import { getAdminBusinessId } from "../../../../utils/authHelper";
import { getEventStaffList } from "../../../../actions/eventActions";
import MultiSelect from "../../../../components/MultiSelect";

class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      totalEvents: 0,
      bookingId: null,
      showViewDetailModal: false,
      isLoading: false,
      pageLoading: false,
      filterShow: false,
      multiSelect: "",
      month: null,
      pagination: {
        current: 1,
        pageSize: 20,
      },
      isEventFilterVisible: true,
      filterStatus: "",
      selectedBookingIds: [],
      selectedBookingDays: [],
      showCancelModal: false,
      showReassignModal: false,
      staff: [],
    };
  }

  /**
   * show bulk cancel modal
   */
  setShowCancelModal = () => {
    this.setState({
      showCancelModal: true,
    });
  };

  /**
   * hide bulk cancel modal
   */
  hideCancelModal = () => {
    this.setState({
      showCancelModal: false,
    });
  };

  /**
   * show bulk reassign modal
   */
  setShowReassignModal = () => {
    this.setState({
      showReassignModal: true,
    });
  };

  /**
   * hide build reassign modal
   */
  hideRessignModal = () => {
    this.setState({
      showReassignModal: false,
    });
  };

  /**
   * get remaining time
   * @param {*} bookingDateTime
   */
  getRemainingTime = (bookingDateTime) => {
    bookingDateTime = moment(bookingDateTime);
    const currentDate = moment(new Date());
    const diff = bookingDateTime.diff(currentDate);
    const diffDuration = moment.duration(diff);

    if (diffDuration.days() > 0) {
      return `Starts in ${diffDuration.days()} ${
        diffDuration.days() > 1 ? "Days" : "Day"
      }, ${diffDuration.hours()} ${
        diffDuration.hours() > 1 ? "Hours" : "Hour"
      } and ${diffDuration.minutes()} ${
        diffDuration.minutes() > 1 ? "Minutes" : "Minute"
      }`;
    } else {
      return `Starts in ${diffDuration.hours()} ${
        diffDuration.hours() > 1 ? "Hours" : "Hour"
      } and ${diffDuration.minutes()} ${
        diffDuration.minutes() > 1 ? "Minutes" : "Minute"
      }`;
    }
  };

  /**
   * hide modal
   */
  hideViewDetailModal = () => {
    this.setState({
      showViewDetailModal: false,
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  onPageSizeChange = (e) => {
    const _this = this;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({
      pagination: {
        current: 1,
        pageSize: e.target.value,
      },
    });
  };

  /**
   * on page change
   * @param {*} page
   * @param {*} pageSize
   */
  onPageChange = (page, pageSize) => {
    const _this = this;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch list events
   */
  fetchListEvents = (params = {}) => {
    const _this = this;
    const { upcomingEvents } = _this.props || {};
    upcomingEvents({
      data: _this.prepareDataParam(params),
      onSuccess: function (response) {
        const { data, total } = response;
        _this.setState({
          events: data,
          totalEvents: total,
          isLoading: false,
          pageLoading: false,
          pagination: { ...params.pagination },
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  };

  /**
   * load upcoming events
   */
  loadUpcomingEvents = () => {
    const _this = this;
    const { pagination } = _this.state;
    _this.setState({
      isLoading: true,
      selectedBookingIds: [],
      selectedBookingDays: [],
    });
    _this.fetchListEvents({ pagination });
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchListEvents({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  /**
   * updating filter
   * @param {*} name
   * @param {*} value
   */
  onUpdateFilters = (name, value) => {
    const searchFilters = { ...this.state.filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    this.setState({
      filter: searchFilters,
      //isLoading: true,
    });
  };

  /**
   * filter records
   */
  filterRecords = () => {
    const _this = this;
    _this.setState({
      isLoading: true,
      filterShow: false,
    });
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {
        ..._this.state.filter,
      },
      {}
    );
  };

  /**
   * on clear filters
   */
  onClearFilters = () => {
    const _this = this;
    this.setState({
      filter: {},
      isLoading: true,
      filterShow: false,
    });
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {},
      {}
    );
  };

  componentDidMount() {
    const _this = this;

    const { pagination } = _this.state;
    const { getEventStaffList } = this.props;

    _this.setState({
      isLoading: true,
    });

    getEventStaffList({
      data: {
        businessId: getAdminBusinessId(),
      },
      onSuccess: (data) => {
        _this.setState({
          staff: data,
        });
      },
      onError: (error) => {
        console.log({
          error,
        });
      },
    });

    _this.fetchListEvents({ pagination });
  }

  /**
   * on view detail click
   * @param {*} bookingData
   */
  onViewDetail = (bookingData) => {
    this.setState({
      bookingId: bookingData.id,
      showViewDetailModal: true,
    });
  };

  /**
   * remove filter section
   */
  onRemoveFilterContainer = () => {
    this.setState({
      isEventFilterVisible: false,
    });
  };

  /**
   * on request invitee to reschedule
   * @param int bookingId
   */
  onRequestInviteeToReschedule = (bookingId) => {
    const _this = this;
    const { requestInviteeToRescheduleAction } = _this.props || {};
    const { pagination } = _this.state;

    _this.setState({
      isLoading: true,
    });
    requestInviteeToRescheduleAction({
      data: { id: bookingId },
      onSuccess: function (response) {
        notification.success(
          "Request invitee to reschedule sent successfully."
        );
        _this.setState({
          showViewDetailModal: false,
        });
        _this.fetchListEvents({ pagination });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  };

  /**
   * on Toggle Booking day checkbox
   * @param string day
   */
  onToggleBookingDayCheckbox = (browserEvent, day) => {
    const _this = this;
    const { selectedBookingDays, events, selectedBookingIds } = this.state;
    const processedBookingDays = [...selectedBookingDays];
    const processedBookingIds = [...selectedBookingIds];
    const found = processedBookingDays.some((bookingDay) => bookingDay === day);

    const dayBookingIds = [];
    Object.keys(events).forEach((key, index) => {
      if (key === day) {
        (events[`${key}`] || []).forEach((event, innerIndex) => {
          dayBookingIds.push(event.id);
        });
      }
    });

    if (browserEvent.target.checked) {
      if (!found) {
        processedBookingDays.push(day);
        dayBookingIds.forEach((dayBookingId, index) => {
          const idFound = processedBookingIds.some((id) => id === dayBookingId);
          if (!idFound) {
            processedBookingIds.push(dayBookingId);
          }
        });
      }
    } else {
      if (found) {
        processedBookingDays.splice(processedBookingDays.indexOf(day), 1);
        dayBookingIds.forEach((dayBookingId, index) => {
          const idFound = processedBookingIds.some((id) => id === dayBookingId);
          if (idFound) {
            processedBookingIds.splice(
              processedBookingIds.indexOf(dayBookingId),
              1
            );
          }
        });
      }
    }
    _this.setState({
      selectedBookingDays: processedBookingDays,
      selectedBookingIds: processedBookingIds,
    });
  };

  /**
   * @param {*} browserEvent
   * @param {*} booking
   */
  onToggleBookingCheckbox = (browserEvent, booking) => {
    const _this = this;
    const { selectedBookingIds } = this.state;
    const processedBookingIds = [...selectedBookingIds];
    const found = processedBookingIds.some((id) => id === booking.id);
    if (browserEvent.target.checked) {
      if (!found) {
        processedBookingIds.push(booking.id);
      }
    } else {
      if (found) {
        processedBookingIds.splice(processedBookingIds.indexOf(booking.id), 1);
      }
    }
    _this.setState({
      selectedBookingIds: processedBookingIds,
    });
  };

  /**
   * Transform values
   * @param {*} options
   * @returns
   */
  transformValue = (options) => {
    let transformValues = [];
    if ((options || []).length > 0) {
      transformValues = (options || []).map((option) => {
        let { first_name, id, last_name } = option || {};
        const name = first_name + " " + last_name;
        return {
          title: name,
          value: id,
        };
      });
    }
    return transformValues;
  };

  render() {
    const {
      isCancelOpen,
      events,
      isLoading,
      pagination,
      showViewDetailModal,
      bookingId,
      filter,
      selectedBookingIds,
      selectedBookingDays,
      showCancelModal,
      showReassignModal,
      staff,
    } = this.state || {};
    const _this = this;
    return (
      <div className="rd_pasevencontth">
        <Row>
          <Col sm="12">
            <div className="filter_bar upcming_bar d-flex justify-content-between align-items-md-center with_btn">
              <h3>Event List</h3>
              <div className="row align-items-center w-auto">
                {selectedBookingIds.length > 0 && (
                  <>
                    <div className="col-auto pe-2">
                      <button
                        className="btn btn-app"
                        onClick={this.setShowReassignModal}
                      >
                        {selectedBookingIds.length === 1
                          ? "Reassign"
                          : "Reassign All"}
                      </button>
                    </div>
                    <div className="col-auto ps-2 pe-2 pe-md-3">
                      <button
                        className="btn rd_cancel w-100 h-auto"
                        onClick={this.setShowCancelModal}
                      >
                        {selectedBookingIds.length === 1
                          ? "Cancel"
                          : "Cancel All"}
                      </button>
                    </div>
                  </>
                )}

                <div className="col-auto">
                  <div className="filter_sec">
                    <button
                      className="btn p-0"
                      onClick={() =>
                        this.setState({ ...this.state, filterShow: true })
                      }
                      style={{ width: "auto" }}
                    >
                      <img src={filterIcon} alt="" style={{ width: 27 }} />
                    </button>
                    <div
                      className={`filter_backdrop ${
                        this.state.filterShow ? "active" : ""
                      }`}
                    ></div>
                    <div
                      className={`filter_content ${
                        this.state.filterShow ? "active" : ""
                      }`}
                    >
                      <div className="position-relative">
                        <div className="top_bar">
                          <h3>Filters</h3>
                          <button
                            className="btn p-0"
                            onClick={this.onClearFilters}
                          >
                            Clear Filters
                          </button>
                        </div>
                        <div className="filter_items_container">
                          <div className="type_filt row justify-content-between mx-0">
                            <div className="col-12 px-0">
                              <label>Event</label>
                            </div>
                            <div className="col-auto px-0">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                  checked
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios1"
                                >
                                  All
                                </label>
                              </div>
                            </div>
                            <div className="col-auto px-0">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                  checked
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios1"
                                >
                                  Created By Me
                                </label>
                              </div>
                            </div>
                            <div className="col-auto px-0">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                  checked
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios1"
                                >
                                  Invited By Others
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label htmlFor="event-name">Event Name</label>
                            <input
                              type="text"
                              id="event-name"
                              placeholder="Event Name ..."
                              class="form-control"
                              value={
                                gotProperty(filter, "event_name")
                                  ? filter["event_name"]
                                  : ""
                              }
                              onChange={(e) => {
                                this.onUpdateFilters(
                                  "event_name",
                                  e.target.value
                                );
                              }}
                            />
                          </div>

                          <div class="form-group">
                            <label htmlFor="event-format">Event Format</label>
                            <Input
                              type="select"
                              name="event_format"
                              id="event-format"
                              value={
                                gotProperty(filter, "event_format_id")
                                  ? filter["event_format_id"]
                                  : ""
                              }
                              onChange={(e) => {
                                this.onUpdateFilters(
                                  "event_format_id",
                                  e.target.value
                                );
                              }}
                            >
                              <option value="">Select Event Format</option>
                              <option value="1">One to One</option>
                              <option value="2">Group</option>
                            </Input>
                          </div>

                          <div className="form-group event-form-group">
                            <label>Staff</label>
                            <MultiSelect
                              data={this.transformValue(staff)}
                              defaultValue={
                                gotProperty(filter, "staff")
                                  ? filter["staff"]
                                  : []
                              }
                              onChangeTreeSelect={(values) => {
                                let assignedStaffs = [];
                                this.transformValue(staff).forEach(
                                  (adminStaff, index) => {
                                    if (values.includes(adminStaff.value)) {
                                      assignedStaffs.push({
                                        title: adminStaff.title,
                                        value: adminStaff.value,
                                      });
                                    }
                                  }
                                );
                                this.onUpdateFilters("staff", [
                                  ...assignedStaffs,
                                ]);
                              }}
                            />
                          </div>

                          <div class="form-group">
                            <label htmlFor="status">Event Status</label>
                            <Input
                              type="select"
                              name="select"
                              id="status"
                              value={
                                gotProperty(filter, "is_active")
                                  ? filter["is_active"]
                                  : ""
                              }
                              onChange={(e) => {
                                this.onUpdateFilters(
                                  "is_active",
                                  e.target.value
                                );
                              }}
                            >
                              <option value="">Select Status</option>
                              <option value="E">Active</option>
                              <option value="D">Deactive</option>
                            </Input>
                          </div>

                          <div class="form-group">
                            <label htmlFor="booking-status">
                              Booking Status
                            </label>
                            <Input
                              type="select"
                              name="booking_status"
                              id="booking-status"
                              value={
                                gotProperty(filter, "booking_status")
                                  ? filter["booking_status"]
                                  : ""
                              }
                              onChange={(e) => {
                                this.onUpdateFilters(
                                  "booking_status",
                                  e.target.value
                                );
                              }}
                            >
                              <option value="">Select Booking Status</option>
                              <option value="A">Show All</option>
                              <option value="U">Upcoming Events</option>
                              <option value="RITR">Reschedule Requested</option>
                              <option value="C">Cancelled Events</option>
                            </Input>
                          </div>
                        </div>
                        <div className="bottom_bar">
                          <button
                            className="btn btn-outline"
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                filterShow: false,
                              })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-app"
                            onClick={this.filterRecords}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Loader isShowLoader={isLoading} />
        {Object.keys(events).length > 0 ? (
          <Fragment>
            {Object.keys(events).map((key, index) => {
              return (
                <Fragment key={index}>
                  <h2>
                    <div className="event-form-group">
                      <div className="form-check-box">
                        <input
                          type="checkbox"
                          name=""
                          id={`day-${index + 1}`}
                          onChange={(e) => {
                            this.onToggleBookingDayCheckbox(e, key);
                          }}
                          checked={selectedBookingDays.some(
                            (day) => day === key
                          )}
                        />
                        <label className="mb-0" htmlFor={`day-${index + 1}`}>
                          <span></span>
                        </label>
                      </div>
                    </div>
                    <span className="rd_archiveiconth"></span>
                    {moment(key, "DD-MM-YYYY").format("dddd, MMMM, DD YYYY")}
                  </h2>
                  <div className="rd_eventcardscontainer align-items-stretch">
                    {(events[`${key}`] || []).map((event, innerIndex) => {
                      if (event.type === "regular") {
                        return (
                          <div className="rd_carditem" key={innerIndex}>
                            {event.status === "RITR" && (
                              <div class="draft_eve no_show resc">
                                Reschedule Requested
                              </div>
                            )}
                            <div className="rd_cardheaderevenpasc">
                              <div className="event-form-group">
                                <div className="form-check-box">
                                  <input
                                    type="checkbox"
                                    name={`booking-${event.id}`}
                                    id={`booking-${event.id}`}
                                    onChange={(e) => {
                                      this.onToggleBookingCheckbox(e, event);
                                    }}
                                    checked={selectedBookingIds.some(
                                      (id) => id === event.id
                                    )}
                                  />
                                  <label
                                    className="mb-0"
                                    htmlFor={`booking-${event.id}`}
                                  >
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                              <h3
                                className={
                                  event.status === "CC" || event.status === "CS"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {event.event_name}
                              </h3>
                              {event.event_format_id === "1" ||
                              event.event_format_id === 1 ? (
                                <p
                                  className={
                                    event.status === "CC" ||
                                    event.status === "CS"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  One on One
                                </p>
                              ) : (
                                <p
                                  className={
                                    event.status === "CC" ||
                                    event.status === "CS"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >{`1 to ${event.no_of_attendies} invitees`}</p>
                              )}
                            </div>
                            <div className="rd_cardbdyevenpasc">
                              {event.status !== "CC" ? (
                                <p
                                  className={
                                    event.status === "CC" ||
                                    event.status === "CS"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  <span className="rd_timelefticon"></span>
                                  {_this.getRemainingTime(
                                    event.booking_date_time
                                  )}
                                </p>
                              ) : null}

                              <p
                                className={
                                  event.status === "CC" || event.status === "CS"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                <span className="rd_timeiconthin"></span>
                                {`${event.start_time} - ${event.end_time}`}
                              </p>
                              <p
                                className={
                                  event.status === "CC" || event.status === "CS"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {getVenueIcon(event.venue)}
                                {event.venue}
                              </p>

                              {event.status === "CC" ||
                              event.status === "CS" ? (
                                <p>
                                  <span className="text-danger">
                                    Cancelled by {event.cancellation_by}
                                  </span>
                                  {event.cancellation_remarks}
                                </p>
                              ) : null}
                            </div>
                            <div className="bottom_btns">
                              <button
                                className="view-detail-btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.onViewDetail(event);
                                  //onModalOpen();
                                }}
                              >
                                View Detail
                              </button>
                              {event.is_admin_invitee === "E" ? (
                                <>
                                  <div className="event_label">
                                    You: Invitee{" "}
                                    <img src={InviteeIcon} alt="Admin Icon" />
                                  </div>
                                  {/* <div className="event_label bg-transparent">
                                <Tooltip title="You are Invitee of this event." color="#fff">
                                  <img src={InviteeIcon} alt="Admin Icon" />
                                </Tooltip>
                              </div> */}
                                </>
                              ) : (
                                <>
                                  <div className="event_label">
                                    You: Host{" "}
                                    <img src={AdminIcon} alt="Admin Icon" />
                                  </div>
                                  {/* <div className="event_label bg-transparent">
                                <Tooltip title="You are Admin of this event." color="#fff">
                                  <img src={AdminIcon} alt="Admin Icon" />
                                </Tooltip>
                              </div> */}
                                </>
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="rd_carditem" key={innerIndex}>
                            <div className="rd_cardheaderevenpasc">
                              <div className="event-form-group">
                                <div className="form-check-box">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="upcomingBulkSelect"
                                  />
                                  <label
                                    className="mb-0"
                                    htmlFor="upcomingBulkSelect"
                                  >
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                              <h3
                                className={
                                  event.status === "CC" || event.status === "CS"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {event.event_name}
                              </h3>
                            </div>
                            <div className="rd_cardbdyevenpasc">
                              {event.status !== "CC" ? (
                                <p
                                  className={
                                    event.status === "CC" ||
                                    event.status === "CS"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  <span className="rd_timelefticon"></span>
                                  {_this.getRemainingTime(
                                    event.booking_date_time
                                  )}
                                </p>
                              ) : null}

                              <p
                                className={
                                  event.status === "CC" || event.status === "CS"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                <span className="rd_timeiconthin"></span>
                                {`${event.start_time} - ${event.end_time}`}
                              </p>
                              <p
                                className={
                                  event.status === "CC" || event.status === "CS"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {getVenueIcon(event.venue)}
                                {event.venue}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        ) : (
          <div className="box_content rd_boxcontentinf"></div>
        )}
        {showViewDetailModal && (
          <UpcomingViewDetailsModal
            bookingId={bookingId}
            showViewDetailModal={showViewDetailModal}
            handleClose={this.hideViewDetailModal}
            loadUpcomingEvents={this.loadUpcomingEvents}
            onRequestInviteeToReschedule={this.onRequestInviteeToReschedule}
          />
        )}
        {Object.keys(events).length > 0 ? (
          <Row className="pb-4 pr-md-3">
            <Col className="text-center" lg="12" md="12">
              <Pagination
                className="list-view-pagination"
                defaultCurrent={pagination.current}
                current={pagination.current}
                total={this.state.totalEvents}
                pageSize={pagination.pageSize}
                onChange={this.onPageChange}
                showSizeChanger={false}
              />
            </Col>
            <Col sm="3" className="table_paginaton">
              <PaginatorDropdown
                onPageSizeChange={this.onPageSizeChange}
                pageSize={pagination.pageSize}
              />
            </Col>
          </Row>
        ) : null}
        {/* Reassign all events Popup */}
        {showCancelModal && (
          <UpcomingCancelModal
            showCancelModal={this.setShowCancelModal}
            handleClose={this.hideCancelModal}
            data={selectedBookingIds}
            isStaffCancellation={false}
            isBulk={true}
            loadUpcomingEvents={this.loadUpcomingEvents}
          />
        )}
        {showReassignModal && (
          <ReassignModal
            showReassignModal={showReassignModal}
            data={selectedBookingIds}
            handleClose={this.hideRessignModal}
            loadUpcomingEvents={this.loadUpcomingEvents}
          />
        )}
      </div>
    );
  }
}

export default connect(null, {
  getEventStaffList,
  upcomingEvents,
  requestInviteeToRescheduleAction,
})(Upcoming);
