import React, { Component } from "react";
import { Input, FormFeedback } from "reactstrap";
import { Field } from "formik";

class Questions extends Component {
  render() {
    const {
      questions,
      errors,
      touched,
      initialValues,
      onFieldChange,
      setFieldTouched,
    } = this.props;

    return (
      <>
        {questions.length > 0 ? (
          <div className="row row-split que_row">
            {(questions || [])
              .filter((question) => question.show_status === "E")
              .map((question, index) => {
                const {
                  id,
                  question: title,
                  question_type,
                  mandatory_status,
                  question_details: options,
                  show_status,
                } = question || {};

                let field = null;

                const questionFieldName = `question-${question_type}-${id}`;

                if (question_type === "text" && show_status === "E") {
                  field = (
                    <>
                      <div className="form-group" key={`question-${index}`}>
                        <label className="form-group-label">
                          {title} {mandatory_status === "E" ? "*" : null}
                        </label>
                        <input
                          type="text"
                          name={`question-text-${id}`}
                          className={`form-control ${
                            errors[questionFieldName] &&
                            touched[questionFieldName] &&
                            "is-invalid"
                          }`}
                          onChange={({ target }) => {
                            const { value } = target;
                            onFieldChange({
                              target: { name: `question-text-${id}`, value },
                            });
                          }}
                          onBlur={() => {
                            setFieldTouched(`question-text-${id}`, true);
                          }}
                        />
                        {errors[`question-${question_type}-${id}`] &&
                          touched[`question-${question_type}-${id}`] && (
                            <FormFeedback className="d-block">
                              {errors[`question-${question_type}-${id}`]}
                            </FormFeedback>
                          )}
                      </div>
                    </>
                  );

                  initialValues[`question-text-${id}`] = "";
                } else if (
                  question_type === "multiline" &&
                  show_status === "E"
                ) {
                  field = (
                    <>
                      <div className="form-group" key={`question-${index}`}>
                        <label className="form-group-label">
                          {title} {mandatory_status === "E" ? "*" : null}
                        </label>
                        <Input
                          type="textarea"
                          className={`form-control ${
                            errors[questionFieldName] &&
                            touched[questionFieldName] &&
                            "is-invalid"
                          }`}
                          name={`question-multiline-${id}`}
                          onChange={({ target }) => {
                            const { value } = target;
                            onFieldChange({
                              target: {
                                name: `question-multiline-${id}`,
                                value,
                              },
                            });
                          }}
                          onBlur={() => {
                            setFieldTouched(
                              `question-${question_type}-${id}`,
                              true
                            );
                          }}
                        />
                        {errors[`question-${question_type}-${id}`] &&
                          touched[`question-${question_type}-${id}`] && (
                            <FormFeedback className="d-block">
                              {errors[`question-${question_type}-${id}`]}
                            </FormFeedback>
                          )}
                      </div>
                    </>
                  );
                  initialValues[`question-multiline-${id}`] = "";
                } else if (question_type === "select" && show_status === "E") {
                  field = (
                    <>
                      <div className="form-group" key={`question-${index}`}>
                        <label className="form-group-label">
                          {title} {mandatory_status === "E" ? "*" : null}
                        </label>
                        <select
                          className={`${
                            errors[questionFieldName] &&
                            touched[questionFieldName] &&
                            "is-invalid"
                          }`}
                          name={`question-select-${id}`}
                          onChange={({ target }) => {
                            const { value } = target;
                            onFieldChange({
                              target: { name: `question-select-${id}`, value },
                            });
                          }}
                          onBlur={() => {
                            setFieldTouched(
                              `question-${question_type}-${id}`,
                              true
                            );
                          }}
                        >
                          <option value="">Select...</option>
                          {(options || []).map((item, index) => {
                            const { field_label, field_value } = item || {};
                            return (
                              <option
                                key={index}
                                value={field_value}
                                className="form-group-label"
                              >
                                {field_label}
                              </option>
                            );
                          })}
                        </select>
                        {errors[`question-${question_type}-${id}`] &&
                          touched[`question-${question_type}-${id}`] && (
                            <FormFeedback className="d-block">
                              {errors[`question-${question_type}-${id}`]}
                            </FormFeedback>
                          )}
                      </div>
                    </>
                  );
                  initialValues[`question-select-${id}`] = "";
                } else if (question_type === "radio" && show_status === "E") {
                  field = (
                    <>
                      <label className="form-group-label">
                        {title} {mandatory_status === "E" ? "*" : null}
                      </label>

                      <div className="row">
                        {(options || []).map((item, index) => {
                          const { field_label, field_value } = item || {};
                          return (
                            <div className="col-md-6 mt-3 mt-md-0" key={`question-radio-${index}`}>
                              <div
                                className="form-check"
                                key={`question-radio-${index}`}
                                // style={{ marginLeft: 40 }}
                              >
                                <input
                                  className={`form-check-input ${
                                    errors[questionFieldName] &&
                                    touched[questionFieldName] &&
                                    "is-invalid"
                                  }`}
                                  type="radio"
                                  name={`question-radio-${id}`}
                                  value={field_value}
                                  onChange={({ currentTarget }) => {
                                    const { value } = currentTarget;
                                    onFieldChange({
                                      target: {
                                        name: `question-radio-${id}`,
                                        value,
                                      },
                                    });
                                  }}
                                  onBlur={() => {
                                    setFieldTouched(
                                      `question-${question_type}-${id}`,
                                      true
                                    );
                                  }}
                                ></input>
                                <label className="form-check-label">
                                  {field_label}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {errors[`question-${question_type}-${id}`] &&
                        touched[`question-${question_type}-${id}`] && (
                          <FormFeedback className="d-block">
                            {errors[`question-${question_type}-${id}`]}
                          </FormFeedback>
                        )}
                    </>
                  );
                  initialValues[`question-radio-${id}`] = "";
                } else if (
                  question_type === "checkbox" &&
                  show_status === "E"
                ) {
                  field = (
                    <>
                      <label className="form-group-label">
                        {title} {mandatory_status === "E" ? "*" : null}
                      </label>

                      <div className="d-flex">
                        {(options || []).map((item, index) => {
                          const { field_label, field_value } = item || {};
                          const fieldName = `question-checkbox-${id}`;
                          const fieldId = `question-checkbox-${id}-${index}`;
                          return (
                            <div
                              className="event-form-group"
                              key={`questions-checkbox-${index}`}
                              style={{ marginLeft: 40 }}
                            >
                              <div className="form-check-box">
                                <input
                                  type="checkbox"
                                  name={fieldName}
                                  id={fieldId}
                                  value={field_value}
                                  onChange={({ target }) => {
                                    const { value, checked } = target;
                                    const checkboxes =
                                      document.getElementsByName(fieldName);
                                    let checkboxesChecked = [];
                                    // loop over them all
                                    for (
                                      let i = 0;
                                      i < checkboxes.length;
                                      i++
                                    ) {
                                      // And stick the checked ones onto an array...
                                      if (checkboxes[i].checked) {
                                        checkboxesChecked.push(
                                          checkboxes[i].value
                                        );
                                      }
                                    }
                                    // Return the array if it is non-empty, or null
                                    let fieldValues =
                                      checkboxesChecked.length > 0
                                        ? checkboxesChecked
                                        : [];

                                    if (checked) {
                                      const fieldValueIndex =
                                        fieldValues.indexOf(value);
                                      if (fieldValueIndex === -1) {
                                        fieldValues.push(value);
                                      }
                                    } else {
                                      const fieldValueIndex =
                                        fieldValues.indexOf(value);
                                      if (fieldValueIndex > -1) {
                                        fieldValues.splice(fieldValueIndex, 1);
                                      }
                                    }
                                    onFieldChange({
                                      target: { name: fieldName, value: fieldValues },
                                    });
                                  }}
                                  onBlur={() => {
                                    setFieldTouched(
                                      `question-${question_type}-${id}`,
                                      true
                                    );
                                  }}
                                ></input>
                                <label htmlFor={fieldId}>
                                  <span></span>
                                  {field_label}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {errors[`question-${question_type}-${id}`] &&
                        touched[`question-${question_type}-${id}`] && (
                          <FormFeedback className="d-block">
                            {errors[`question-${question_type}-${id}`]}
                          </FormFeedback>
                        )}
                    </>
                  );
                  initialValues[`question-checkbox-${id}`] = [];
                }

                return (
                  <div className="col-12" key={index}>
                    {field}
                  </div>
                );
              })}
          </div>
        ) : null}
      </>
    );
  }
}

export default Questions;
